










import { Vue, Prop, Component } from 'vue-property-decorator';
@Component
export default class InsLocation extends Vue {
  @Prop() private readonly LocationTips!: string;
  @Prop() private readonly Homepage!: string;
  @Prop() private readonly Column!: string;
  @Prop() private readonly Title!: string;
}
